#wrap-load-animation {
  position: absolute;
  inset: 0;
  top: 50%;
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  z-index: 1;
  transition: all 0.5s;
}
#wrap-load-animation.my-node-enter,
#wrap-load-animation.my-node-enter p {
  opacity: 0;
  display: flex;
}

#wrap-load-animation.my-node-enter-active,
#wrap-load-animation.my-node-enter-active p {
  opacity: 1;
  display: flex;
}
#wrap-load-animation.my-node-enter-done,
#wrap-load-animation.my-node-enter-done p {
  opacity: 1;
  display: flex;
}

#wrap-load-animation.my-node-exit,
#wrap-load-animation.my-node-exit p {
  opacity: 0;
  display: flex;
}

#wrap-load-animation.my-node-exit-done,
#wrap-load-animation.my-node-exit-done p {
  opacity: 0;
  display: none;
}
