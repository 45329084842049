*,
body {
  font-family: 'New-Hero', 'Rubik', sans-serif;
  font-weight: 400;
  box-sizing: border-box;
  outline: none;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
@font-face {
  font-family: 'New-Hero';
  src: url('assets/fonts/NewHero-Thin.woff2');
  font-weight: 100;
}
@font-face {
  font-family: 'New-Hero';
  src: url('assets/fonts/NewHero-UltraLight.woff2');
  font-weight: 200;
}
@font-face {
  font-family: 'New-Hero';
  src: url('assets/fonts/NewHero-Light.woff2');
  font-weight: 300;
}
@font-face {
  font-family: 'New-Hero';
  src: url('assets/fonts/NewHero-Regular.woff2');
  font-weight: 400;
}
@font-face {
  font-family: 'New-Hero';
  src: url('assets/fonts/NewHero-Medium.woff2');
  font-weight: 500;
}
@font-face {
  font-family: 'New-Hero';
  src: url('assets/fonts/NewHero-SemiBold.woff2');
  font-weight: 600;
}
@font-face {
  font-family: 'New-Hero';
  src: url('assets/fonts/NewHero-Bold.woff2');
  font-weight: 700;
}
@font-face {
  font-family: 'New-Hero';
  src: url('assets/fonts/NewHero-ExtraBold.woff2');
  font-weight: 800;
}
@font-face {
  font-family: 'New-Hero';
  src: url('assets/fonts/NewHero-Super.woff2');
  font-weight: 900;
}
@font-face {
  font-family: 'ES-Rebond-Grotesque-Classic';
  src: url('assets/fonts/ESRebondGrotesqueClassic-Semibold.ttf');
  font-weight: 600;
}
@font-face {
  font-family: 'ES-Rebond-Grotesque-Classic';
  src: url('assets/fonts/ESRebondGrotesqueClassic-Bold.ttf');
  font-weight: 700;
}
@font-face {
  font-family: 'ES-Rebond-Grotesque-Classic';
  src: url('assets/fonts/ESRebondGrotesqueClassic-Extrabold.ttf');
  font-weight: 800;
}

@font-face {
  font-family: 'Rubik';
  src: url('assets/fonts/Rubik-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 900; /* Supports a range of weights */
  font-style: normal;
}

@font-face {
  font-family: 'Rubik';
  src: url('assets/fonts/Rubik-Italic-VariableFont_wght.ttf') format('truetype');
  font-weight: 100 900;
  font-style: italic;
}

/* default font of add new text on cavans  */
@font-face {
  font-family: 'Poppins-SemiBold';
  src: url('assets/fonts/Poppins-SemiBold.ttf');
  font-weight: 400;
}
/* .masonry-grid {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: -8px;
  width: auto;
}
.masonry-grid_column {
  padding-left: 8px;
  background-clip: padding-box;
}
.masonry-grid_column > div {
  margin-bottom: 8px;
} */
.linear-gradient-text {
  font-size: 40px;
  font-weight: 800;
  line-height: 46px;
  background: -webkit-linear-gradient(to right, #ff1866, #fd0167, #f00188, #dd01a0, #d203b4, #c811cb);
  background: -o-linear-gradient(to right, #ff1866, #fd0167, #f00188, #dd01a0, #d203b4, #c811cb);
  background: -moz-linear-gradient(to right, #ff1866, #fd0167, #f00188, #dd01a0, #d203b4, #c811cb);
  background: linear-gradient(to right, #ff1866, #fd0167, #f00188, #dd01a0, #d203b4, #c811cb);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
}
.linear-gradient-text-to-left {
  background: -webkit-linear-gradient(to left, #ff1866, #fd0167, #f00188, #dd01a0, #d203b4, #c811cb);
  background: -o-linear-gradient(to left, #ff1866, #fd0167, #f00188, #dd01a0, #d203b4, #c811cb);
  background: -moz-linear-gradient(to left, #ff1866, #fd0167, #f00188, #dd01a0, #d203b4, #c811cb);
  background: linear-gradient(to left, #ff1866, #fd0167, #f00188, #dd01a0, #d203b4, #c811cb);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
}
.linear-gradient-text-2 {
  font-size: 40px;
  font-weight: 800;
  line-height: 61px;
  background: -webkit-linear-gradient(to bottom, #ff1866, #fd0167, #f00188, #dd01a0, #d203b4, #c811cb);
  background: -o-linear-gradient(to bottom, #ff1866, #fd0167, #f00188, #dd01a0, #d203b4, #c811cb);
  background: -moz-linear-gradient(to bottom, #ff1866, #fd0167, #f00188, #dd01a0, #d203b4, #c811cb);
  background: linear-gradient(to bottom, #ff1866, #fd0167, #f00188, #dd01a0, #d203b4, #c811cb);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
}


.linear-gradient-bg {
  background: -webkit-linear-gradient(to bottom, #ff1866, #fd0167, #f00188, #dd01a0, #d203b4, #c811cb);
  background: -o-linear-gradient(to bottom, #ff1866, #fd0167, #f00188, #dd01a0, #d203b4, #c811cb);
  background: -moz-linear-gradient(to bottom, #ff1866, #fd0167, #f00188, #dd01a0, #d203b4, #c811cb);
  background: linear-gradient(to bottom, #ff1866, #fd0167, #f00188, #dd01a0, #d203b4, #c811cb);
}

.linear-gradient-bg-to-right {
  background: -webkit-linear-gradient(to right, #ff1866, #fd0167, #f00188, #dd01a0, #d203b4, #c811cb);
  background: -o-linear-gradient(to right, #ff1866, #fd0167, #f00188, #dd01a0, #d203b4, #c811cb);
  background: -moz-linear-gradient(to right, #ff1866, #fd0167, #f00188, #dd01a0, #d203b4, #c811cb);
  background: linear-gradient(to right, #ff1866, #fd0167, #f00188, #dd01a0, #d203b4, #c811cb);
}

.good-work-text {
  font-size: 24px;
  font-weight: 800;
  line-height: 32px;
  background: -webkit-linear-gradient(to right, #ff1866, #fd0167, #f00188, #dd01a0, #d203b4, #c811cb);
  background: -o-linear-gradient(to right, #ff1866, #fd0167, #f00188, #dd01a0, #d203b4, #c811cb);
  background: -moz-linear-gradient(to right, #ff1866, #fd0167, #f00188, #dd01a0, #d203b4, #c811cb);
  background: linear-gradient(to right, #ff1866, #fd0167, #f00188, #dd01a0, #d203b4, #c811cb);
  background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0;
}

:root {
  --modal-scale: 1;
}

/* media css */
@media (max-height: 800px) {
  .h-heading-inspector {
    height: 50px;
    > p {
      font-size: 18px !important;
    }
  }
  .mt-50 {
    margin-top: 16px;
  }
  .mb-50 {
    margin-bottom: 50px;
  }
  :root {
    --modal-scale: 0.8;
  }

  .scale-modal-0-8 {
    transform: scale(var(--modal-scale));
  }
}

@media (min-height: 800px) {
  .h-heading-inspector {
    min-height: 62px;
  }
  .mt-50 {
    margin-top: 50px;
  }
  .mb-50 {
    margin-bottom: 50px;
  }
}

#btn-remove-obj:hover .overlay,
#btn-remove-bg:hover .overlay,
#btn-magic-background:hover .overlay,
#btn-enhance-image:hover .overlay,
#btn-remove-obj:active .overlay,
#btn-remove-bg:active .overlay,
#btn-magic-background:active .overlay,
#btn-enhance-image:active .overlay
 {
  display: block !important;
}

#container-overlay-remove {
  display: none;
}

.project-item {
  aspect-ratio: 1;
}
@media (min-width: 3000px) {
  .wrap-projects {
    gap: 32px
  }
  .project-item {
    width: calc((100% - 224px) / 8);
  }
}
@media (min-width: 2500px) and (max-width: 2999px) {
  .wrap-projects {
    gap: 32px
  }
  .project-item {
    width: calc((100% - 192px) / 7);
  }
}
@media (min-width: 2000px) and (max-width: 2499px) {
  .wrap-projects {
    gap: 32px
  }
  .project-item {
    width: calc((100% - 160px) / 6);
  }
}
@media (min-width: 1440px) and (max-width: 1999px) {
  .wrap-projects {
    gap: 32px
  }
  .project-item {
    width: calc((100% - 128px) / 5);
  }
}
@media (min-width: 1240px) and (max-width: 1439px) {
  .wrap-projects {
    gap: 16px
  }
  .project-item {
    width: calc((100% - 48px) / 4);
  }
}
@media (min-width: 900px) and (max-width: 1239px) {
  .wrap-projects {
    gap: 16px;
  }
  .project-item {
    width: calc((100% - 32px) / 3);
  }
}
@media (max-width: 899px) {
  .wrap-projects {
    gap: 16px;
    width: 899px;
  }
  .project-item {
    width: 282px;
  }
}