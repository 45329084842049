/* AnimatedInspectorWrapper.css */

.animatedWrapper {
  top: 0;
  height: 100%;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.5);
  transform-origin: center left;
}

.enter {
  z-index: 3;
  animation: slideIn 0.3s ease-out forwards;
}

.enterWithoutAnimation {
  z-index: 3;
  animation: slideIn 0s steps(1) forwards;
}

.exit {
  z-index: 3;
  animation: slideOut 0.4s ease-in-out backwards;
}

.nonOnTop {
  animation: hideNonOnTop 0.4s ease-in-out backwards;
  opacity: 0;
}

.nonOnTop > div {
  scale: 0.97;
}

.onTop {
  animation: showOnTop 0.4s ease-in-out backwards;
}

.exitWithoutAnimation {
  z-index: 3;
  animation: slideOut 0s steps(1) backwards;
}

@keyframes slideIn {
  0% {
    translate: 360px;
  }
  100% {
    translate: 0px;
  }
}

@keyframes slideOut {
  0% {
    translate: 0px;
  }
  100% {
    translate: 380px;
  }
}

@keyframes showOnTop {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes hideNonOnTop {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
