/* color picker */
.wrap-color-picker {
  width: 100%;
  padding: 0 16px;
}
.wrap-color-picker .react-colorful {
  width: auto;
}
.wrap-color-picker .react-colorful .react-colorful__saturation {
  border-radius: 4px;
  margin-bottom: 19px;
}
.wrap-color-picker .react-colorful .react-colorful__hue {
  border-radius: 12px;
  height: 18px;
}
.wrap-color-picker .react-colorful__pointer {
  cursor: pointer;
  border: 2px solid #ffffff;
  border-radius: 50%;
}
.wrap-color-picker .react-colorful__saturation .react-colorful__pointer {
  width: 16px;
  height: 16px;
  box-shadow: 0px 10px 6.4px rgba(0, 0, 0, 0.07), 0px 0px 10.4px rgba(0, 0, 0, 0.04);
}
.wrap-color-picker .react-colorful__hue .react-colorful__pointer {
  width: 24px;
  height: 24px;
  box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.15), 0px 4px 8px rgba(0, 0, 0, 0.15);
}
/* input */
.wrap-input {
}
.wrap-input input {
  width: 100%;
  height: 40px;
  padding-left: 12px;
  background: #ffffff;
  border: 1px solid #e5e5e5;
  border-radius: 8px;

  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;

  letter-spacing: -0.006em;
  color: #666666;
}
.wrap-input input:hover,
.wrap-input input:focus {
  border: 1px solid #666666;
}
