:root {
  --animation-time: 0.4s;
}

@keyframes scale-natural {
  from {
    display: flex;
    transform: scale(calc(0.9 * var(--modal-scale)));
    opacity: 0;
  }
  to {
    transform: scale(calc(1 * var(--modal-scale)));
    opacity: 1;
  }
}

@keyframes scale-down {
  from {
    transform: scale(calc(1 * var(--modal-scale)));
    opacity: 1;
  }
  to {
    transform: scale(calc(0.9 * var(--modal-scale)));
    opacity: 0;
    display: none;
  }
}

.scaleNatural {
  animation: scale-natural var(--animation-time) ease-in-out forwards;
  display: flex;
}

.scaleNaturalNoAnimation {
  animation: scale-natural 0s steps(1) forwards;
  display: flex;
}

.scaleDown {
  animation: scale-down var(--animation-time) ease-in-out forwards;
}

.scaleDownNoAnimation {
  animation: scale-down 0s steps(1) forwards;
}

@keyframes show-wrap-modal {
  from {
    opacity: 0;
    background: rgba(0, 0, 0, 0);
  }
  to {
    opacity: 1;
    background: rgba(0, 0, 0, 0.5);
  }
}

@keyframes hide-wrap-modal {
  from {
    opacity: 1;
    background: rgba(0, 0, 0, 0.5);
  }
  to {
    opacity: 0;
    background: rgba(0, 0, 0, 0);
    display: none;
  }
}

.showWrapModal {
  animation: show-wrap-modal var(--animation-time) ease-in-out forwards;
}

.hideWrapModal {
  animation: hide-wrap-modal var(--animation-time) ease-in-out forwards;
}

.showWrapModalNoAnimation {
  animation: show-wrap-modal 0s steps(1) forwards;
}

.hideWrapModalNoAnimation {
  animation: hide-wrap-modal 0s steps(1) forwards;
}

.modalContent {
  display: flex;
  justify-content: center;
  align-items: center;
}

@media (max-height: 700px) {
  .modalContent {
    align-items: flex-start;
  }
}